import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="content-container">
        <div className="text-section">
          <h1 className="name-title">Michael Cutajar</h1>
          <div className="bio-list">
            <p>👨‍💼 Former CEO of <a href="https://metaverse-architects.com/" target="_blank" rel="noopener noreferrer">Metaverse Architects</a> (shares sold)</p>
            <p>📊 Accountant turned Software Developer</p>
            <p>🤖 Building Augmented Intelligence at <a href="https://www.glimpse.wiki/" target="_blank" rel="noopener noreferrer">Glimpse</a></p>
            <p>🌍 SF & Malta Based</p>
            <p>🏢 Ex PWC | 🎲 Ex Betfair</p>
            <p>🍻 Founder of Malta Pub Crawl</p>
          </div>
          <div className="contact-links">
            <a href="https://www.linkedin.com/mynetwork/" target="_blank" rel="noopener noreferrer">
              <button className="social-button linkedin">LinkedIn</button>
            </a>
            <a href="mailto:michael@glimpse.wiki">
              <button className="social-button email">Email</button>
            </a>
          </div>
          <button className="cta-button" onClick={() => window.open('https://calendly.com/michael-glimpse/30min', '_blank')}>
            Schedule a call
          </button>
        </div>
        
        <div className="video-container">
          <iframe
            src="https://www.youtube.com/embed/BxYc7hhwQlU?rel=0"
            title="My story.. and how I got here."
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default App;